<template>
    <div>
        <div v-for="(number,index) in revertResultNumbers"
             :key="index"

             :style="getBrickPosition(index)"
             style="position: absolute"
        >
            <img :src="brickImage"/>

            <span class="text">{{ number }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        resultNumbers: {
            type: Array,
            required: true,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            brick: {
                width: 120,
                height: 73
            },
        }
    },
    computed: {
        brickImage() {
            return require('@/assets/games/keno/egypt-keno/brick.png');
        },
        revertResultNumbers() {
            let numbers = this.resultNumbers;
            return numbers.reverse();
        }
    },
    methods: {
        getBrickPosition(index) {
            let left = 0, top = 0;

            if (index < 2) {
                left += this.brick.width * 0.5 + this.brick.width * (index % 3);
            } else {
                let level = Math.floor((index + 1) / 3);
                let current = (index + 1) % 3;

                left = this.brick.width * current;
                top = this.brick.height * level;
            }

            return {
                left: `${left}px`,
                top: `${top}px`
            };
        }
    }
}
</script>

<style scoped>
.text {
    font-weight: bold;
    font-size: 32px;
    color: #2c1cff;
    cursor: default;
    white-space: nowrap;

    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
